import React from 'react'
import { LoaderBounce } from './Loader'

export default function Load({
  show = false,
}) {
  return (
    <div style={{
      position: 'absolute',
      display: show ? 'flex' : 'none',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
      background: 'rgba(255,255,255,.4)',
    }}>
      <LoaderBounce size="md" />
    </div>
  )
}
