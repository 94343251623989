import client from "helper/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Load from "components/common/Load";
import AlertMessage from "components/common/AlertMessage";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  getCompanyList,
  getStateCityList,
} from "store/reducers/list-combo/action";
import { range } from "lodash";
import { colourStyles } from "helper/customComponentStyle";

const initForm = {
  company_name: "",
  first_name: "",
  last_name: "",
  email: "",
  fixed_phone_number: "",
  mobile_phone_number: "",
  user_country: "",
  user_city: "",
  comment: "",
};

const initOptionsSelect = {
  company_name: "",
  user_country: "",
  user_city: "",
};

export default function RegisterIsignum({
  isOpen = false,
  onClose = () => undefined,
  onSaveSuccess = () => undefined,
}) {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initForm);
  const [optionsSelect, setOptionsSelect] = useState(initOptionsSelect);
  const [isSaving, setIsSaving] = useState(false);
  const [emailAdmin, setEmailAdmin] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userEmailSent, setUserEmailSent] = useState("");
  const [idStateCompany, setIdStateCompany] = useState(0);
  const [idState, setIdState] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const { companyList, stateCityList } = useSelector(
    (state) => state.listCombo
  );
  const { id } = useParams();
  const country_options = [
    { value: "Australia", label: "Australia" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  let emailDestination = "anza.isignum.requests@ericsson.com";

  const onSave = () => {
    client
      .post(`isignum_request_data/add?email=${emailDestination}`, form)
      .then((res) => {
        setAlertMessage(
          "Your request has been sent. Please wait for approval."
        );
      });
  };

  const onAccept = () => {
    setAlertMessage(null);
    onClose();
    onSaveSuccess();
  };

  const emailGetDataApi = (id, user_email) => {
    client
      .get(
        `registration_company_categorization_signup/get_data_admin_by_company_id/${id}`
      )
      .then((res) => {
        if (res.data.length != 0) {
          setEmailAdmin(res.data[0].email);
          setUserEmail(user_email);
          setIdStateCompany(res.data[0].anza_company_admin_states[0].state_id);
          return res.data[0].anza_company_admin_states[0].state_id;
        }
      });
  };

  useEffect(() => {
    setForm(initForm);
    setOptionsSelect(initOptionsSelect);
    setIsSaving(false);
    dispatch(getCompanyList());
    dispatch(getStateCityList());
  }, [dispatch]);

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <Load show={isSaving} />
        <ModalHeader toggle={onClose}>Request ISignum</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="name" className="">
                  Company Name *
                </label>
                <Select
                  styles={colourStyles}
                  isClearable
                  className="text-dark"
                  options={companyList}
                  defaultOptions
                  placeholder="Enter Company Name.."
                  value={optionsSelect.company_name}
                  onChange={(value) => {
                    if (value) {
                      var id_state_company = emailGetDataApi(
                        value.id,
                        value.email
                      );
                      if (id_state_company != idState) {
                        if (value.email) {
                          setOptionsSelect({
                            ...optionsSelect,
                            company_name: value,
                          });
                          setForm({ ...form, company_name: value.label });
                          setUserEmailSent(value.email);
                        } else {
                          setOptionsSelect({
                            ...optionsSelect,
                            company_name: value,
                          });
                          setForm({ ...form, company_name: value.label });
                          setUserEmailSent("");
                        }
                      } else {
                        setOptionsSelect({
                          ...optionsSelect,
                          company_name: value,
                        });
                        setForm({ ...form, company_name: value.label });
                        setUserEmailSent(emailAdmin);
                      }
                    } else {
                      setOptionsSelect({
                        ...optionsSelect,
                        company_name: value,
                      });
                      setForm({ ...form, company_name: null });
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name" className="">
                  First Name *
                </label>
                <input
                  required
                  id="first_name"
                  name="first_name"
                  placeholder="Enter First Name.."
                  autoComplete="chrome-off"
                  value={form.first_name}
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setForm({ ...form, first_name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="name" className="">
                  Last Name *
                </label>
                <input
                  required
                  id="last_name"
                  name="last_name"
                  placeholder="Enter Last Name.."
                  autoComplete="chrome-off"
                  value={form.last_name}
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setForm({ ...form, last_name: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="">
                  Email Address *
                </label>
                <input
                  required
                  id="email"
                  name="email"
                  autoComplete="chrome-off"
                  placeholder="Enter Email Address.."
                  value={form.email}
                  autoComplete="email"
                  type="email"
                  className="form-control"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="">
                  Fixed Phone Number
                </label>
                <input
                  required
                  id="fixed_phone_number"
                  name="fixed_phone_number"
                  autoComplete="chrome-off"
                  placeholder="Enter Fixed Phone Number.."
                  value={form.fixed_phone_number}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setForm({ ...form, fixed_phone_number: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="">
                  Mobile Phone Number *
                </label>
                <input
                  required
                  id="mobile_phone_number"
                  name="mobile_phone_number"
                  autoComplete="chrome-off"
                  placeholder="Enter Mobile Phone Number.."
                  value={form.mobile_phone_number}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setForm({ ...form, mobile_phone_number: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="email" className="">
                  Country *
                </label>
                <Select
                  styles={colourStyles}
                  isClearable
                  className="text-dark"
                  options={country_options}
                  placeholder="Enter Country.."
                  defaultOptions
                  value={optionsSelect.user_country}
                  onChange={(value) => {
                    if (value) {
                      setOptionsSelect({
                        ...optionsSelect,
                        user_country: value,
                      });
                      setForm({ ...form, user_country: value.label });
                    } else {
                      setOptionsSelect({
                        ...optionsSelect,
                        user_country: value,
                      });
                      setForm({ ...form, user_country: null });
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="">
                  State *
                </label>
                <Select
                  isClearable
                  styles={colourStyles}
                  className="text-dark"
                  options={stateCityList}
                  defaultOptions
                  placeholder="Enter State.."
                  value={optionsSelect.user_city}
                  onChange={(value) => {
                    if (value) {
                      setIdState(idStateCompany);
                      if (value.id != idStateCompany) {
                        setOptionsSelect({
                          ...optionsSelect,
                          user_city: value,
                        });
                        setForm({ ...form, user_city: value.label });
                        setUserEmailSent(userEmail);
                      } else {
                        setOptionsSelect({
                          ...optionsSelect,
                          user_city: value,
                        });
                        setForm({ ...form, user_city: value.label });
                        setUserEmailSent(emailAdmin);
                      }
                    } else {
                      setOptionsSelect({ ...optionsSelect, user_city: value });
                      setForm({ ...form, user_city: null });
                      setUserEmailSent(userEmail);
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="">
                  Comment
                </label>
                <input
                  id="comment"
                  name="comment"
                  autoComplete="chrome-off"
                  placeholder="Enter Comment.."
                  value={form.comment}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setForm({ ...form, comment: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            title="Request ISIGNUM"
            className="btn btn-primary mr-2"
            onClick={() => {
              if (userEmailSent != "") {
                emailDestination = userEmailSent;
              }
              onSave();
            }}
            disabled={
              form.company_name === "" ||
              form.first_name === "" ||
              form.last_name === "" ||
              form.email === "" ||
              form.mobile_phone_number === "" ||
              form.user_country === "" ||
              form.user_city === ""
            }
          >
            <i className="fa fa-send"></i> Request ISIGNUM
          </button>
          <button className="btn btn-danger" onClick={onClose}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
      <AlertMessage
        isOpen={!!alertMessage}
        alertMessage={alertMessage}
        toggleModal={onAccept}
      />
    </div>
  );
}
