import React, { useEffect } from "react";
import useQuery from "helper/useQuery";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserProfile, onSystemLoginSSO } from "store/reducers/user/action";
import config from "config/app.config";

export default function Authenticate() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("appName", config.baseName);
    const token = query.get("token");
    const ssoToken = query.get("ssoToken");
    const ssoInfo = JSON.parse(localStorage.getItem('ssoInfo'))


    const ssoInfoData = {
      name: ssoInfo?.account?.name,
      userName: ssoInfo?.account?.userName,
      idToken: ssoInfo?.account?.idToken
    }

    ssoToken
      ? dispatch(onSystemLoginSSO(ssoInfoData, history))
      : token
      ? dispatch(getUserProfile(token))
      : (window.location.href = "/");
  }, [dispatch, query]);
  return (
    <div
      style={{ height: "100vh", width: "100vw" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <h6 sx={{ ml: 1 }}>Authenticate user...</h6>
    </div>
  );
}
