import initialState from "./initialState";
import {
  SET_WORKERS_LIST,
  SET_WORKERS_LIST_LOAD,
  SET_WORKERS_LIST_ERROR,
  SET_WORKER_SELECTED,
} from "./types";

const workers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_WORKERS_LIST: {
      return {
        ...state,
        listWorker: {
          ...initialState.listWorker,
          workers: payload,
        },
      };
    }
    case SET_WORKERS_LIST_LOAD: {
      return {
        ...state,
        listWorker: {
          ...initialState.listWorker,
          load: true,
        },
      };
    }
    case SET_WORKERS_LIST_ERROR: {
      return {
        ...state,
        listWorker: {
          ...initialState.listWorker,
          error: payload,
        },
      };
    }
    case SET_WORKER_SELECTED: {
      return {
        ...state,
        workerSelected: payload || null,
      };
    }
    default:
      return state;
  }
};

export default workers;
