import React, { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/EricssonLogo.png";
import client from "../../helper/client";
import { saveLocalStorageToken } from "../../helper";
import jwt from "jsonwebtoken";
import ConfirmationMessage from "../../components/common/ConfirmationMessage";
import RegisterIsignum from "../SignUp/RegisterIsignum";
import { useDispatch, useSelector } from "react-redux";
import { resetCombos } from "store/reducers/list-combo/action";
import { fetchMenu } from "store/reducers/menu/action";
import { onSystemLogin } from "store/reducers/user/action";
import CardHeader from "../../../node_modules/reactstrap/es/CardHeader";
import CardFooter from "../../../node_modules/reactstrap/es/CardFooter";
import { Button } from "bootstrap";

function ErrorPage() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    username: "",
    password: "",
    remember_me: false,
  });
  const [signUpOpts, setSignUpOpts] = useState(false);
  const [registerIsignum, setRegisterIsignum] = useState(false);

  const history = useHistory();
  const error = useSelector((state) => state.user.error);
  function onSubmit(e) {
    e.preventDefault();
    dispatch(onSystemLogin(form));

    return;
    const { username, password, remember_me } = form;
    client
      .post(`login/verification_anza?remember_me=${remember_me ? 1 : 0}`, {
        username,
        password,
      })
      .then(async (res) => {
        const dataToken = jwt.decode(res.data.token);
        saveLocalStorageToken("user", JSON.stringify(dataToken));
        if (
          dataToken.active_status === "PENDING" ||
          dataToken.active_status === "PENDING_RESIGN" ||
          dataToken.active_status === "POOLING" ||
          dataToken.user_type === "member"
        ) {
          history.push("/signup");
        } else if (dataToken.active_status === "") {
          window.alert(
            `${username} is not active. Please contact your administrator.`
          );
        } else {
          saveLocalStorageToken("token", res.data.token);
          saveLocalStorageToken("user_type", dataToken.user_type);
          saveLocalStorageToken("user_role", dataToken.user_role);
          await dispatch(fetchMenu(dataToken.country, dataToken.type_id,dataToken?.id));
          window.location.reload();
        }
      })
      .catch((err) =>
        alert(err.response.data ? err.response.data : "Login Failed")
      );
  }

  useEffect(() => {
    localStorage.clear();
    dispatch(resetCombos());
  }, []);

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card>
              <CardHeader>{error?.title}</CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p className="mb-4">{error?.message}</p>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <button
                      onClick={() => {
                        history.push("/logoout");
                        localStorage.clear();
                      }}
                      className="btn btn-primary"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ConfirmationMessage
          closeOutsideButton={false}
          confirmTitle="Do you have a ISIGNUM/SIGNUM?"
          isOpen={signUpOpts}
          confirmMessage={``}
          actionOK={() => history.push("/signup")}
          actionCancel={() => {
            setSignUpOpts(false);
            setRegisterIsignum(true);
          }}
          cancelLabel="No"
          okLabel="Yes"
        /> */}
        {/* {registerIsignum && (
          <RegisterIsignum
            isOpen={registerIsignum}
            onClose={() => setRegisterIsignum(false)}
          />
        )} */}
      </Container>
    </div>
  );
}

export default ErrorPage;
