const initialState = {
  listWorker: {
    workers: [],
    load: false,
    error: null,
  },
  workerSelected: null,

  workerExperience: {
    experiences: [],
    load: false,
    error: null,
  },

  workerCertificate: {
    certificates: [],
    load: false,
    error: null,
  },
};

export default initialState;
