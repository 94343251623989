import { combineReducers } from "redux";
import listComboReducer from "./reducers/list-combo";
import masterCompanyDataReducer from "./reducers/master-company-data";
import layoutReducer from "./reducers/layout";
import userReducer from "./reducers/user";
import usersReducer from "./reducers/users";
import companyReducer from "./reducers/company";
import menuReducer from "./reducers/menu";
import workersReducer from "./reducers/workers";
import { helperReducer } from "./reducers/helper/reducer";
import { errorReducer } from "./reducers/error/index";
import { loadingReducer } from "./reducers/loading/index";

export default combineReducers({
  listCombo: listComboReducer,
  masterCompanyData: masterCompanyDataReducer,
  layout: layoutReducer,
  user: userReducer,
  users: usersReducer,
  menu: menuReducer,
  workers: workersReducer,
  helper: helperReducer,
  error: errorReducer,
  loading: loadingReducer,
  company:companyReducer

});
