import initialState from "./initialState";

//  test
import {
  SET_MASTER_COMPANY_DATA_PAGE,
  SET_MASTER_COMPANY_DATA_FORM,
  SET_ARCHIVE_STATUS,
  RESET_FORM,
} from "./types";

const listComboReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MASTER_COMPANY_DATA_PAGE: {
      return {
        ...state,
        page: payload,
      };
    }
    case SET_MASTER_COMPANY_DATA_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          ...payload,
        },
      };
    }
    case SET_MASTER_COMPANY_DATA_FORM: {
    }

    case RESET_FORM: {
      return {
        ...state,
        form: initialState.form,
      };
    }

    case SET_ARCHIVE_STATUS: {
      return {
        ...state,
        is_archived: payload,
      };
    }

    default:
      return state;
  }
};

export default listComboReducer;
