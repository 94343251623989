import {
  SET_GLOBAL_LOADER_STATE,
  SET_CURRENT_VIEW,
  SET_BROWSER_INCOMPATIBLE,
} from "./types";

// The logic still needs to be improved;
const setCurrentView =
  (payload = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_CURRENT_VIEW,
      payload: {
        CURRENT_VIEW: payload.CURRENT_VIEW || getState().layout.CURRENT_VIEW,
        PREV_VIEW: payload.PREV_VIEW
          ? payload.PREV_VIEW
          : getState().layout.CURRENT_VIEW || null,
      },
    });
  };

const setGlobalLoader = () => (dispatch) => {
  dispatch({
    type: SET_GLOBAL_LOADER_STATE,
  });
};

const setBrowserIncompatible = (payload) => (dispatch) => {
  dispatch({
    type: SET_BROWSER_INCOMPATIBLE,
    payload,
  });
};

export { setGlobalLoader, setCurrentView, setBrowserIncompatible };
