import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRouteSSO = ({ component: Component, ssoInfo, ...rest }) => {
  const user = JSON.parse(localStorage.getItem("state"))?.user;

  localStorage.setItem("ssoInfo", JSON.stringify(ssoInfo));
  // const [setcheckedUserWithSSO, checkedUserWithSSO] = useState(false);
  const emailSSO = ssoInfo?.account?.userName;
  const emailBluecard = user?.data?.email;

  // useEffect(() => {
  //   if (emailSSO === emailBluecard) {
  //     setcheckedUserWithSSO(true);
  //   }
  // });
  return (
    <Route
      {...rest}
      render={(props) =>
        ssoInfo?.jwtIdToken &&
        ssoInfo?.jwtIdToken !== "" &&
        user?.token &&
        emailBluecard === emailSSO ? (
          <Component {...props} />
        ) : ssoInfo?.jwtIdToken &&
          ssoInfo?.jwtIdToken !== "" &&
          !user?.token ? (
          <Redirect
            to={`/authenticate?ssoToken=${ssoInfo?.jwtIdToken}`}
            {...props}
          />
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
  );
};

export default PrivateRouteSSO;
