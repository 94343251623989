import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, Row, Col, ModalHeader, ModalFooter } from 'reactstrap'
import Load from './Load'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmTitle: PropTypes.string,
  confirmMessage: PropTypes.any,
  actionCancel: PropTypes.func.isRequired,
  actionOK: PropTypes.func.isRequired,
  isLoad: PropTypes.bool,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  closeOutsideButton: PropTypes.bool,
}

class ConfirmationMessage extends Component {
  render() {
    let { 
      isOpen, 
      confirmTitle, 
      confirmMessage, 
      actionCancel, 
      actionOK, 
      isLoad, 
      okLabel = 'OK', 
      cancelLabel = 'Cancel',
      closeOutsideButton = true
    } = this.props

    return (
      <Modal centered isOpen={isOpen} toggle={() => closeOutsideButton && actionCancel(!isOpen)} className="text-center">
        {isLoad && <Load show={isLoad} />}
        {(confirmTitle || confirmTitle === '') && <ModalHeader className="border-bottom-0">{confirmTitle}</ModalHeader>}
        {confirmMessage && confirmMessage !== '' && <ModalBody>
          <div>{confirmMessage}</div>
          <Row>
            <Col sm="12" className="d-flex justify-content-end">
            </Col>
            </Row>
        </ModalBody>}
        <ModalFooter className="border-top-0">
          <button className="btn btn-primary mr-2" onClick={() => actionOK()}>
            {okLabel}
          </button>
          <button className="btn btn-danger" onClick={() => actionCancel()}>
            {cancelLabel}
          </button>
        </ModalFooter>
      </Modal>
    )
  }
}

ConfirmationMessage.propTypes = propTypes
export default ConfirmationMessage
