import initialState from "./initialState";
import {
  RESET_USER,
  SET_USER_DATA,
  SET_USER_ERROR,
  SET_USER_TOKEN,
} from "./types";

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_TOKEN: {
      return {
        ...state,
        token: payload || null,
        load: false,
      };
    }
    case SET_USER_DATA: {
      return {
        ...state,
        data: payload || {},
        load: false,
      };
    }
    case SET_USER_ERROR: {
      return {
        ...initialState,
        error: payload || null,
      };
    }
    case RESET_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default user;
