import axios from "axios";
import store from "store/index";
import config from "config/app.config";
import { setLoading } from "store/reducers/loading/index";

const client = axios.create({
  baseURL: config.api,
});

client.interceptors.request.use(
  (config) => {
    if (store?.getState()?.user?.token) {
      config.headers["Authorization"] = `Bearer ${
        store?.getState()?.user?.token || null
      }`;
    }
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    config.headers["Access-Control-Allow-Origin"] = "*";
    document.querySelector("#circular-progress-loading").style.display = "flex";

    return config;
  },
  (error) => {
    // setLoading(false);
    document.querySelector("#circular-progress-loading").style.display = "none";

    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    // setLoading(true);
    document.querySelector("#circular-progress-loading").style.display = "none";

    return response;
  },
  function (error) {
    // setLoading(false);
    document.querySelector("#circular-progress-loading").style.display = "none";

    if (error.response && error.response.status === 401) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default client;
