import React from "react";
import { useHistory } from "react-router-dom";

export default function UserNotFound({ logoutSSO, accountInfo }) {
  const history = useHistory();
  return (
    <div
      style={{ height: "100vh", width: "100vw" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <h6>User Not Found</h6>
      <br />
      <button
        className="btn btn-primary"
        onClick={() =>
          history.push(`/signup?type=new&tokenSSO=${accountInfo.jwtIdToken}`)
        }
      >
        Register User
      </button>
    </div>
  );
}
