const icons = {
  Approvals: "fa fa-check",
  Companies: "fa fa-building",
  Users: "fa fa-user-o",
  Workers: "fa fa-users",
  Report: "fa fa-file",
  "My Profile": "fa fa-user",
};

export default icons;
