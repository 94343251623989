import React from 'react';

const sizes = ['sm', 'md', 'lg'];
const LoaderBounce = ({size, rootClasses, dotClasses}) => {
    const setSize = size && sizes.includes(size) ? size : 'sm';
    rootClasses = rootClasses || '';
    dotClasses = dotClasses || '';
    return (
        <div className={`spinner ${setSize} ${rootClasses}`}>
            <div className={`bounce1 ${dotClasses}`}></div>
            <div className={`bounce2 ${dotClasses}`}></div>
            <div className={`bounce3 ${dotClasses}`}></div>
        </div>
    )
}

export { LoaderBounce }