import axios from "axios";
import config from "config/app.config";

const saveLocalStorageToken = async (item, value) => {
  try {
    await localStorage.setItem(item, value);
  } catch (error) {
    throw error;
  }
};

const getLocalStorage = (value) => {
  var data = localStorage.getItem(value);
  if (data) {
    try {
      return JSON.parse(localStorage.getItem(value));
    } catch {
      return data;
    }
  }
};

const updateUserLocale = (objectToEdit) => {
  const current = getLocalStorage("user");
  const newUser = { ...current, ...objectToEdit };
  saveLocalStorageToken("user", JSON.stringify(newUser));
};

const updateVaccineLocal = (objectToEdit) => {
  const current = getLocalStorage("vaccine");
  const newVaccine = { ...current, ...objectToEdit };
  saveLocalStorageToken("vaccine", JSON.stringify(newVaccine));
};

const goSignOut = () => {
  localStorage.clear();
  window.location = "/";
};

const getQueryParam = (json) => {
  Object.keys(json).forEach((key) => json[key] === "" && delete json[key]);
  return Object.keys(json)
    .map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(json[k]);
    })
    .join("&");
};

const showDashboardPop = () => {
  let dashbardPop = window.open(
    "/dashboard-pop",
    "Dashboard",
    `height=${window.screen.availHeight},width=${window.screen.availWidth}`
  );
  dashbardPop.moveTo(0, 0);
};

const mapUserType = (user_type) => {
  return user_type === "member"
    ? "Member"
    : user_type === "engineer"
    ? "Engineer"
    : user_type === "tech_lead"
    ? "Technical Lead"
    : user_type === "company_member"
    ? "Company Member"
    : user_type === "company_admin"
    ? "Company Admin"
    : user_type;
};

const baseUrlAPI = config?.api;

const isSignum = (email) => {
  const example = email.toLowerCase();

  const domainEmail = example.includes("ericsson.com");

  return domainEmail;
};
// const baseUrlAPI = 'http://localhost:5000/'
//const baseUrlAPI = 'localhost:3000/'

export {
  saveLocalStorageToken,
  getLocalStorage,
  updateUserLocale,
  goSignOut,
  getQueryParam,
  showDashboardPop,
  mapUserType,
  baseUrlAPI,
  updateVaccineLocal,
  isSignum,
};
