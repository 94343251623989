import { loginApi, loginSsoApi } from "services/authentication";
import { getUserProfileApi } from "services/user";
import { setGlobalLoader } from "../layout/action";
import { SET_USER_DATA, SET_USER_ERROR, SET_USER_TOKEN } from "./types";
import jwt from "jsonwebtoken";
import { fetchMenu } from "../menu/action";

const setUserError = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_ERROR,
    payload,
  });
};

const onSystemLogin =
  ({ username = "", password = "", remember_me = 0 }, history) =>
  (dispatch) => {
    dispatch(setGlobalLoader());
    loginApi(username, password, remember_me)
      .then((res) => {
        window.location.href = `/authenticate?token=${res?.token}`;
      })
      .catch((err) => {
        history.push("/error");
        dispatch(setGlobalLoader());
        dispatch(setUserError(err?.data));
      })
      .finally(() => dispatch(setGlobalLoader()));
  };

const onSystemLoginSSO = (tokenSSO, history) => (dispatch) => {
  localStorage.setItem("token_sso", tokenSSO);
  loginSsoApi(tokenSSO)
    .then((res) => dispatch(getUserProfile(res?.token)))
    .catch((err) => {
      if(err.status == 403) {
        dispatch(setUserError(err?.data));
        history.push("/error");
      }
      if(err?.status === 400) {
        history.push("/user-not-found");
      }

    });
};

const getUserProfile = (token, disabledReload, history) => (dispatch) => {
  const dataToken = jwt.decode(token);
  console.log("data token", dataToken);
  getUserProfileApi(dataToken?.id, token)
    .then((res) => {
      if (res.length > 0) {
        dispatch({
          type: SET_USER_TOKEN,
          payload: token,
        });
        dispatch({
          type: SET_USER_DATA,
          payload: res[0],
        });

        console.log("role", res[0]);
        console.log("dataToken_tes",dataToken?.id)
        
        dispatch(fetchMenu(res[0]?.nationality, res[0]?.type_id,dataToken?.id));
        console.log("dataToken_tes3",dataToken?.id)
        
      } else {
        history.push("/logout");
      }
      !disabledReload && window.location.reload();
    })
    .catch((err) => console.log(err));
};

export { onSystemLogin, onSystemLoginSSO, getUserProfile };
