import React from "react";
import { Modal, ModalBody } from "reactstrap";

function AlertMessage({
  isOpen = false,
  alertMessage = "",
  toggleModal = () => undefined,
  disableToggle = false,
}) {
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={() => (disableToggle ? undefined : toggleModal(!isOpen))}
      className="text-center"
    >
      <ModalBody>
        <p>{alertMessage}</p>
        {!disableToggle && (
          <button
            className="btn btn-success"
            onClick={() => toggleModal(!isOpen)}
          >
            Ok
          </button>
        )}
      </ModalBody>
    </Modal>
  );
}
export default AlertMessage;
