import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import CommonRoute from "./route/CommonRoute";
import config from "config/app.config";
import AzureAD from "react-aad-msal";
import { authProvider } from "config/authProvider";
import UserNotFound from "pages/Auth/UserNotFound";
import PrivateRouteSSO from "route/PrivateRouteSSO";
import Authenticate from "pages/Auth/Authenticate";
import PrivateRoute from "route/PrivateRoute";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "components/common/AlertMessage";
import { isBrowserChrome, isBrowserIE } from "helper/browserCheck";
import { setBrowserIncompatible } from "store/reducers/layout/action";
import moment from "../node_modules/moment/moment";
import ErrorPage from "views/ErrorPage/index";
import { setLoading } from "store/reducers/loading/index";
import client from "helper/client";
import store from "store/index";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const CircularProgressLoading = () => (
  <div
    id="circular-progress-loading"
    style={{
      position: "fixed",
      height: "100vh",
      zIndex: "2000",
      top: 0,
      left: 0,
      width: "100vw",
      display: "none",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(0,0,0, 0.2)",
    }}
  >
    <div class="loader-5 center">
      <span></span>
    </div>
  </div>
);

// Containers
const EMPLayout = React.lazy(() => import("./containers/EMPLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
const SignUp = React.lazy(() => import("./views/SignUp"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const Page403 = React.lazy(() => import("./views/Pages/Page403"));

const ProfileDashboardPop = React.lazy(() =>
  import("./views/ProfileDashboard/ProfileDashboardPop")
);

const Logout = React.lazy(() => import("./pages/Auth/Logout"));

function App() {
  const { browserIncompatible } = useSelector((state) => state.layout);
  const dispatch = useDispatch();

  // for loading when have request to backend
  const loadingState = useSelector((selector) => selector.loading.loading);

  console.log("loading", loadingState);

  useEffect(() => {
    if (
      (config.env === "DEVELOPMENT" || config.env === "PRODUCTION") &&
      localStorage.getItem("appName") !== config?.baseName
    ) {
      localStorage.removeItem("state");
      localStorage.setItem("appName", config.baseName);
      window.location.reload();
      return;
    }
    if (isBrowserIE) {
      dispatch(setBrowserIncompatible(true));
    }
  }, []);

  console.log("moment version", moment.version);

  console.log("test", config)

  return (
    <React.Suspense fallback={loading()}>
      {(config.env === "PRODUCTION" || config.env === "DEVELOPMENT") && (
        <AzureAD provider={authProvider} forceLogin={false}>
          {({ login, logout, authenticationState, error, accountInfo }) => {
            if (authenticationState === "Unauthenticated") {
              login();
              return null;
            } else if (authenticationState === "InProgress") {
              return "Logging In...";
            }
            return (
              <BrowserRouter basename={config.baseName}>
                <Switch>
                  <Route
                    path="/user-not-found"
                    render={(props) => (
                      <UserNotFound
                        logoutSSO={logout}
                        accountInfo={accountInfo}
                      />
                    )}
                  />
                  <CommonRoute
                    exact
                    path="/error"
                    name="Error Page"
                    component={ErrorPage}
                  />
                  <CommonRoute
                    exact
                    path="/signup"
                    name="Sign Up Page"
                    component={SignUp}
                  />
                  <CommonRoute path="/authenticate" component={Authenticate} />
                  <Route
                    exact
                    path="/logout"
                    render={(props) => (
                      <Logout
                        logoutSSO={logout}
                        authenticationState={authenticationState}
                        accountInfo={accountInfo}
                      />
                    )}
                  />
                  <PrivateRouteSSO
                    ssoInfo={accountInfo}
                    path="/update-worker"
                    name="Update Worker"
                    component={SignUp}
                  />
                  <PrivateRouteSSO
                    ssoInfo={accountInfo}
                    path="/"
                    component={EMPLayout}
                  />
                </Switch>
              </BrowserRouter>
            );
          }}
        </AzureAD>
      )}
      {config.env === "LOCAL" && (
        <BrowserRouter basename={config.baseName}>
          <Switch>
            <CommonRoute
              exact
              path="/login"
              name="Login Page"
              component={Login}
            />
            <CommonRoute
              exact
              path="/error"
              name="Error Page"
              component={ErrorPage}
            />
            <CommonRoute
              exact
              path="/forgot_password"
              name="Forgot Password"
              component={ForgotPassword}
            />
            <CommonRoute
              exact
              path="/forgot_password/:token/:email"
              name="Forgot Password"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/dashboard-pop"
              name="Dashboard Window"
              component={ProfileDashboardPop}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route exact path="/403" name="Page 403" component={Page403} />

            <CommonRoute
              exact
              path="/signup"
              name="Sign Up Page"
              component={SignUp}
            />
            <CommonRoute path="/authenticate" component={Authenticate} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute
              path="/update-worker"
              name="Update Worker"
              component={SignUp}
            />
            <PrivateRoute path="/" name="Home" component={EMPLayout} />
          </Switch>
        </BrowserRouter>
      )}
      <AlertMessage
        alertMessage="We detect that you are using Internet Explorer  (Internet Explorer is retiring on June 15, 2022), for best experience, please use another browser."
        isOpen={!!browserIncompatible}
        disableToggle
      />

      <CircularProgressLoading />
    </React.Suspense>
  );
}

export default App;
