import axios from "axios";
import client from "./client";

const fetchHeaders = {
  Authorization: `Bearer ${localStorage.token}`,
  Accept: "application/json",
  "Content-Type": "application/json;charset=utf-8",
};

const CancelToken = axios.CancelToken;
let source = {};

export const fetchGet = ({
  url,
  enableCancelToken = true,
  configHeader = {},
}) =>
  new Promise((resolve, reject) => {
    const baseUri = url.split(/[?#]/)[0];
    if (source[baseUri]) {
      source[baseUri].cancel();
    }
    source[baseUri] = CancelToken.source();

    client
      .get(url, {
        headers: {
          ...fetchHeaders,
          ...configHeader,
        },
        cancelToken: enableCancelToken ? source[baseUri].token : null,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

export const fetchPost = ({ url, data, configHeader = {}, extra }) =>
  new Promise((resolve, reject) => {
    const baseUri = url.split(/[?#]/)[0];
    if (source[baseUri]) {
      source[baseUri].cancel();
    }
    source[baseUri] = CancelToken.source();

    client({
      url,
      method: "post",
      headers: {
        ...fetchHeaders,
        ...configHeader,
      },
      ...extra,
      data,
      cancelToken: source[baseUri].token,
      validateStatus: (status) => status >= 200 && status <= 300,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

export const fetchPut = ({ url, data, configHeader = {} }) =>
  new Promise((resolve, reject) => {
    const baseUri = url.split(/[?#]/)[0];
    if (source[baseUri]) {
      source[baseUri].cancel();
    }
    source[baseUri] = CancelToken.source();

    client({
      url,
      method: "put",
      headers: {
        ...fetchHeaders,
        ...configHeader,
      },
      data,
      cancelToken: source[baseUri].token,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

export const fetchDelete = ({ url, configHeader = {} }) =>
  new Promise((resolve, reject) => {
    const baseUri = url.split(/[?#]/)[0];
    if (source[baseUri]) {
      source[baseUri].cancel();
    }
    source[baseUri] = CancelToken.source();

    client
      .delete(url, {
        headers: {
          ...fetchHeaders,
          ...configHeader,
        },
        cancelToken: source[baseUri].token,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

export const fetchPatch = ({ url, data, configHeader = {} }) =>
  new Promise((resolve, reject) => {
    const baseUri = url.split(/[?#]/)[0];
    if (source[baseUri]) {
      source[baseUri].cancel();
    }
    source[baseUri] = CancelToken.source();

    client({
      url,
      method: "patch",
      headers: {
        ...fetchHeaders,
        ...configHeader,
      },
      data,
      cancelToken: source[baseUri].token,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
