import { PROCESS_MODAL } from "./types";

export const initialState = {
  modal: {
    open: false,
    type: "",
    ids: "",
    loading: false,
    updated: false,
    data: [],
  },
};

export function helperReducer(state = initialState, action) {
  switch (action.type) {
    case PROCESS_MODAL:
      return { ...state, modal: action.payload };
      break;

    default:
      return state;
      break;
  }
}
