import { fetchPost } from "helper/fetch";

export const loginApi = async (username, password, remember_me) => {
  return await fetchPost({
    url: `login/verification?remember_me=${remember_me ? 1 : 0}`,
    data: {
      username,
      password,
    },
  });
};

export const loginSsoApi = async (tokenSSO) => {
  return await fetchPost({
    url: `login/sso`,
    data: {
      token: tokenSSO,
    },
  });
};
