import initialState from "./initialState";
import { SET_IS_LOADING,SET_COMPANY_HISTORIES } from "./types";

const company = (state = initialState, { type, payload }) => {
  console.log('type_tes',type)
  console.log('payload_tes',payload)

  switch (type) {
   
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case SET_COMPANY_HISTORIES: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
};

export default company;
