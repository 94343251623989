import {
  SET_LIST_COMBO_COUNTRY,
  SET_LIST_COMBO_STATE,
  SET_LIST_COMBO_COMPANY_PARENT,
  SET_LIST_COMBO_CITY,
  SET_LIST_COMBO_TYPE_OF_DOC,
  SET_LIST_COMBO_COMPANY_ACCOUNT,
  SET_LIST_COMBO_COMPANY_CATEGORIZATION,
  SET_LIST_COMBO_ROLE,
  SET_LIST_COMBO_PROJECT,
  SET_LIST_COMBO_TYPES,
  RESET_ALL_COMBOS,
  SET_LIST_COMBO_USER_ROLE,
  SET_LIST_COMBO_USER_TYPE,
  SET_LIST_COMBO_COMPANY,
  SET_LIST_COMBO_CAPITAL_CITY,
  SET_LIST_COMBO_STATE_CITY,
} from "./types";
import client from "../../../helper/client";
import config from "config/app.config";

const setComboCountry = (payload) => (dispatch) => {
  dispatch({
    type: SET_LIST_COMBO_COUNTRY,
    payload,
  });
};
const setComboState = (payload) => (dispatch) => {
  dispatch({
    type: SET_LIST_COMBO_STATE,
    payload,
  });
};
const setComboCompanyParent = (payload) => (dispatch) => {
  dispatch({
    type: SET_LIST_COMBO_COMPANY_PARENT,
    payload,
  });
};
const setComboCity = (payload) => (dispatch) => {
  dispatch({
    type: SET_LIST_COMBO_CITY,
    payload,
  });
};

const getCompanyAccount = () => (dispatch, getState) => {
  getState().listCombo.companyAccountList.length === 0 &&
    client
      .get("company_account_md")
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_COMPANY_ACCOUNT,
          payload:
            res.data.length > 0 && res.data[0]
              ? res.data[0].map((x) => ({
                  ...x,
                  label: x.ca_name,
                  value: x.id,
                }))
              : [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_COMPANY_ACCOUNT,
          payload: [],
        })
      );
};

const getCategorization = () => (dispatch, getState) => {
  // getState().listCombo.companyCategorizationList.length === 0 &&
  client
    .get("categorization_md")
    .then((res) => {
      console.log(res);
      dispatch({
        type: SET_LIST_COMBO_COMPANY_CATEGORIZATION,
        payload:
          res.data.length > 0 && res.data[0]
            ? res.data[0].map((x) => ({
                ...x,
                label: x.cat_name,
                value: x.id,
              }))
            : [],
      });
    })
    .catch((err) =>
      dispatch({
        type: SET_LIST_COMBO_COMPANY_CATEGORIZATION,
        payload: [],
      })
    );
};

const getTypeOfDoc = () => (dispatch, getState) => {
  getState().listCombo.typeOfDocList.length === 0 &&
    client
      .get("type_of_doc_md/get_data_priority")
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_TYPE_OF_DOC,
          payload: res.data.map((x) => ({
            ...x,
            label: x.type_name,
            value: x.id,
          })),
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_TYPE_OF_DOC,
          payload: [],
        })
      );
};

const getRoleList = (user_id) => (dispatch, getState) => {
  client
    .get(`role_id_md_signup`, {
      params: {
        user_id,
      },
    })
    .then((res) => {
      dispatch({
        type: SET_LIST_COMBO_ROLE,
        payload:
          res?.data?.map((x) => ({
            ...x,
            label: x.role_name,
            value: x.id,
          })) || [],
      });
    })
    .catch((err) => console.log("err getRoleList ==> ", err));
};

const getProjectList = () => (dispatch, getState) => {
  getState().listCombo.roleList.length === 0 &&
    client
      .get("company_account_md_signup")
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_PROJECT,
          payload:
            res?.data[0]?.map((x) => ({
              ...x,
              label: x.ca_name,
              value: x.id,
              doses: x.doses,
            })) || [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_PROJECT,
          payload: [],
        })
      );
};

const getTypesList = () => (dispatch, getState) => {
  getState().listCombo.typesList.length === 0 &&
    client
      .get("type_id_md_signup")
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_TYPES,
          payload: res.data.map((x) => ({
            ...x,
            label: x.type_name,
            value: x.id,
          })),
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_TYPES,
          payload: [],
        })
      );
};

const getUserRoleList = (opts) => (dispatch, getState) => {
  const { userRoleList } = getState().listCombo;

  (userRoleList?.length === 0 || opts?.isNew) &&
    client
      .get("user_role/get_data_all")
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_USER_ROLE,
          payload:
            res?.data?.map((x) => ({
              ...x,
              label: x.role_name,
              value: x.id,
            })) || [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_USER_ROLE,
          payload: [],
        })
      );
};

const getUserTypeList = (opts) => (dispatch, getState) => {
  const { userTypeList } = getState().listCombo;

  (userTypeList?.length === 0 || opts?.isNew) &&
    client
      .get("user_type/get_data_all")
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_USER_TYPE,
          payload:
            res?.data?.map((x) => ({
              ...x,
              label: x.type_name,
              value: x.id,
            })) || [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_USER_TYPE,
          payload: [],
        })
      );
};

const getCompanyList = (opts) => (dispatch, getState) => {
  const { companyList } = getState().listCombo;

  (companyList?.length === 0 || opts?.isNew) &&
    client
      .get(
        `companies/get_all_companies?country_id=${config?.baseCountryId}&child=true`
      )
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_COMPANY,
          payload:
            res?.data?.map((x) => ({
              ...x,
              label: x.company_name,
              value: x.id,
            })) || [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_COMPANY,
          payload: [],
        })
      );
};

const getCapitalCityList = (opts) => (dispatch, getState) => {
  const { capitalCityList } = getState().listCombo;

  (capitalCityList?.length === 0 || opts?.isNew) &&
    client
      .get(`anza_capital_cities`)
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_CAPITAL_CITY,
          payload:
            res?.data?.result?.map((x) => ({
              ...x,
              label: x.capital_city,
              value: x.id,
            })) || [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_CAPITAL_CITY,
          payload: [],
        })
      );
};

const getStateCityList = (opts) => (dispatch, getState) => {
  const { stateCityList } = getState().listCombo;

  (stateCityList?.length === 0 || opts?.isNew) &&
    client
      .get(`states_signup/get_data_by_country_id/7`)
      .then((res) => {
        dispatch({
          type: SET_LIST_COMBO_STATE_CITY,
          payload:
            res?.data?.map((x) => ({
              ...x,
              label: x.state,
              value: x.id,
            })) || [],
        });
      })
      .catch((err) =>
        dispatch({
          type: SET_LIST_COMBO_STATE_CITY,
          payload: [],
        })
      );
};

const resetCombos = () => (dispatch) => {
  dispatch({
    type: RESET_ALL_COMBOS,
  });
};

export {
  setComboCountry,
  setComboState,
  setComboCompanyParent,
  setComboCity,
  getCompanyAccount,
  getCategorization,
  getTypeOfDoc,
  getRoleList,
  getProjectList,
  getTypesList,
  getUserRoleList,
  getUserTypeList,
  getCompanyList,
  getCapitalCityList,
  getStateCityList,
  resetCombos,
};
