import initialState from "./initialState";
import {
  SET_LIST_COMBO_COUNTRY,
  SET_LIST_COMBO_STATE,
  SET_LIST_COMBO_CITY,
  SET_LIST_COMBO_TYPE_OF_DOC,
  SET_LIST_COMBO_COMPANY_ACCOUNT,
  SET_LIST_COMBO_COMPANY_CATEGORIZATION,
  SET_LIST_COMBO_ROLE,
  SET_LIST_COMBO_PROJECT,
  SET_LIST_COMBO_TYPES,
  RESET_ALL_COMBOS,
  SET_LIST_COMBO_USER_ROLE,
  SET_LIST_COMBO_USER_TYPE,
  SET_LIST_COMBO_COMPANY,
  SET_LIST_COMBO_CAPITAL_CITY,
  SET_LIST_COMBO_STATE_CITY,
} from "./types";

const listComboReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_COMBO_COUNTRY: {
      return {
        ...state,
        countryList: payload,
      };
    }
    case SET_LIST_COMBO_STATE: {
      return {
        ...state,
        stateList: payload,
      };
    }
    case SET_LIST_COMBO_CITY: {
      return {
        ...state,
        cityList: payload,
      };
    }
    case SET_LIST_COMBO_TYPE_OF_DOC: {
      return {
        ...state,
        typeOfDocList: payload,
      };
    }
    case SET_LIST_COMBO_COMPANY_ACCOUNT: {
      return {
        ...state,
        companyAccountList: payload,
      };
    }
    case SET_LIST_COMBO_COMPANY_CATEGORIZATION: {
      return {
        ...state,
        companyCategorizationList: payload,
      };
    }
    case SET_LIST_COMBO_ROLE: {
      return {
        ...state,
        roleList: payload,
      };
    }
    case SET_LIST_COMBO_PROJECT: {
      return {
        ...state,
        projectList: payload,
      };
    }
    case SET_LIST_COMBO_TYPES: {
      return {
        ...state,
        typesList: payload,
      };
    }
    case SET_LIST_COMBO_USER_ROLE: {
      return {
        ...state,
        userRoleList: payload,
      };
    }
    case SET_LIST_COMBO_USER_TYPE: {
      return {
        ...state,
        userTypeList: payload,
      };
    }
    case SET_LIST_COMBO_COMPANY: {
      return {
        ...state,
        companyList: payload,
      };
    }

    case SET_LIST_COMBO_CAPITAL_CITY: {
      return {
        ...state,
        capitalCityList: payload,
      };
    }
    case SET_LIST_COMBO_STATE_CITY: {
      return {
        ...state,
        stateCityList: payload,
      };
    }
    case RESET_ALL_COMBOS: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default listComboReducer;
