export const colourStyles = {
  input: (styles) => ({ ...styles, color: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#0077DB" : null,
      color: "#fff",
    };
  },
};
