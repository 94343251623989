import initialState from "./initialState";
import { SET_USERS, SET_IS_LOADING,SET_USER_HISTORIES } from "./types";

const user = (state = initialState, { type, payload }) => {
  //console.log('type_tes',type)
  //console.log('payload_tes',payload)

  switch (type) {
    case SET_USERS: {
      return {
        ...state,
        data: payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case SET_USER_HISTORIES: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
};

export default user;
