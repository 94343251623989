export const SET_LIST_COMBO_COUNTRY = "SET_LIST_COMBO_COUNTRY";
export const SET_LIST_COMBO_STATE = "SET_LIST_COMBO_STATE";
export const SET_LIST_COMBO_COMPANY_PARENT = "SET_LIST_COMBO_COMPANY_PARENT";
export const SET_LIST_COMBO_CITY = "SET_LIST_COMBO_CITY";

export const SET_LIST_COMBO_TYPE_OF_DOC = "SET_LIST_COMBO_TYPE_OF_DOC";
export const SET_LIST_COMBO_COMPANY_ACCOUNT = "SET_LIST_COMBO_COMPANY_ACCOUNT";
export const SET_LIST_COMBO_COMPANY_CATEGORIZATION =
  "SET_LIST_COMBO_COMPANY_CATEGORIZATION";
export const SET_LIST_COMBO_ROLE = "SET_LIST_COMBO_ROLE";
export const SET_LIST_COMBO_PROJECT = "SET_LIST_COMBO_PROJECT";
export const SET_LIST_COMBO_TYPES = "SET_LIST_COMBO_TYPES";

export const SET_LIST_COMBO_USER_ROLE = "SET_LIST_COMBO_USER_ROLE";
export const SET_LIST_COMBO_USER_TYPE = "SET_LIST_COMBO_USER_TYPE";
export const SET_LIST_COMBO_COMPANY = "SET_LIST_COMBO_COMPANY";
export const SET_LIST_COMBO_CAPITAL_CITY = "SET_LIST_COMBO_CAPITAL_CITY";
export const SET_LIST_COMBO_STATE_CITY = "SET_LIST_COMBO_STATE_CITY";

export const RESET_ALL_COMBOS = "RESET_ALL_COMBOS";
