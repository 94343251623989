import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const saveToLocalStorage = (state) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("state", serializedState);
};
const loadFromLocalStorage = () => {
  const serializedState = localStorage.getItem("state");
  if (serializedState === null) return undefined;
  return JSON.parse(serializedState);
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const presistedState = loadFromLocalStorage();
const store = createStore(
  rootReducer,
  presistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() =>
  saveToLocalStorage({
    layout: store.getState().layout,
    user: store.getState().user,
    listCombo: store.getState().listCombo,
    menu: store.getState().menu,
    helper: store.getState().helper,
  })
);

export default store;
