const initialState = {
  countryList: [],
  stateList: [],
  cityList: [],
  typeOfDocList: [],
  companyAccountList: [],
  companyCategorizationList: [],
  roleList: [],
  projectList: [],
  typesList: [],
  userRoleList: [],
  userTypeList: [],
  companyList: [],
  capitalCityList: [],
  stateCityList: [],
};

export default initialState;
