const initialState = {
  data: [],
  page: "",
  is_archived: 0,
  form: {
    tab: 0,
    formData: {},
    categorization: [],
    account: [],
    detailedInfo: [],
    admin: [],
    documentLibrary: {},
  },
};

export default initialState;

//test
