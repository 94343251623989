const initialState = {
  loading: false,
};

const TYPES = {
  SET_LOADING: "SET_LOADING",
};

const loadingReducer = (state = initialState, { type, payload }) => {
  console.log("payload ", payload);
  switch (type) {
    case TYPES.SET_LOADING:
      return {
        loading: payload,
      };

    default:
      return state;
  }
};

const setLoading = (payload) => (dispatch) => {
  console.log("loading payload", payload);
  dispatch({
    type: TYPES.SET_LOADING,
    payload: payload,
  });
};

export { loadingReducer, setLoading };
