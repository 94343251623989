import { fetchGet } from "helper/fetch";

export const getUserProfileApi = async (userId, token) => {
  return await fetchGet({
    url: `user/get_data_user_data_master_by_id/${userId}`,
    configHeader: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
};
