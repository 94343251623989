// Firefox 1.0+
const isBrowserFirefox = typeof InstallTrigger !== "undefined";

// Internet Explorer 6-11
const isBrowserIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
const isBrowserEdge = !isBrowserIE && !!window.StyleMedia;

// Chrome 1 - 71
const isBrowserChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

export { isBrowserFirefox, isBrowserIE, isBrowserEdge, isBrowserChrome };
