import initialState from "./initialState";
import { SET_SIDEBAR_MENU } from "./types";

const menuReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SIDEBAR_MENU: {
      console.log("menu payload", payload);
      localStorage.setItem("sidebar_menu", JSON.stringify(payload));
      return {
        ...state,
        sidebarMenu: payload,
      };
    }
    default:
      return state;
  }
};

export default menuReducer;
