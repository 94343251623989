import client from "helper/client";
import icons from "helper/icons";
import { SET_SIDEBAR_MENU } from "./types";

const menuNames = [
  "My Profile",
  "Users",
  "Companies",
  "Approvals",
  "Workers",
  "Report",
  "Worker Nomination",
  "User History",
  "Worker History",
  "Company History",
  "Activity Logs",
  "Send Message",
  "Message History",
  "Broadcast Comms",
  "Audit Queue"

];

const fetchMenu = (companyId, typeId, user_id) => async (dispatch, getState) => {
  console.log('fetch_menu_tes',user_id)
  const url = `/form_mapping/get_data_form_mapping_by_type_country_id2/${companyId}/${typeId}/${user_id}`;
  const response = await client.get(url);

  console.log('response1234:',response);
  console.log('menu type id',typeId)
  const result = response.data
    .filter((dt) => !dt.sub_menu || !!dt.sub_menu?.length)
    .map((dt) => {
      const newDt = { ...dt };
      newDt.index = menuNames.indexOf(dt.menu);
      newDt.name = dt.menu;
      newDt.icon = icons[dt.menu] || "fa fa-asterisk";
      delete newDt.menu;
      return newDt;
    });

  dispatch({
    payload: result,//.sort((a, b) => a.index - b.index),
    type: SET_SIDEBAR_MENU,
  });
};

export { fetchMenu };
