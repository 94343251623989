import { MsalAuthProvider, LoginType } from "react-aad-msal";
import config from "./app.config";

// Msal Configurations
const azureConfig = {
  auth: {
    authority:
      "https://login.microsoftonline.com/92e84ceb-fbfd-47ab-be52-080c6b87953f/",
    clientId: "dcc1c390-ecb8-4048-909b-d2db98ae5e3e",
    // clientId: "dcc1c390-ecb8-4048-909b-d2db98ae5e3e",
    
    redirectUri: config.baseUrl,
    // validateAuthority: true,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    // navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["user.read.all"],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  azureConfig,
  authenticationParameters,
  options
);
