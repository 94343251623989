const types = {
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_STATUS_MESSAGE: "SET_STATUS_MESSAGE",
};

const initialState = {
  message: "",
  status: 200,
};

const errorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_MESSAGE: {
      return {
        message: payload?.message,
        status: payload?.status,
      };
    }

    default:
      return state;
  }
};

const setErrorMessage = (payload) => (dispatch) => {
  dispatch({
    type: types.SET_ERROR_MESSAGE,
    payload: payload,
  });
};

export { errorReducer, setErrorMessage };
