import initialState from "./initialState";
import {
  SET_BROWSER_INCOMPATIBLE,
  SET_CURRENT_VIEW,
  SET_GLOBAL_LOADER_STATE,
} from "./types";

const layoutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GLOBAL_LOADER_STATE: {
      return {
        ...state,
        globalLoaderState: !state.globalLoaderState,
      };
    }
    case SET_CURRENT_VIEW: {
      return {
        ...state,
        PREV_VIEW: payload.PREV_VIEW,
        CURRENT_VIEW: payload.CURRENT_VIEW,
      };
    }
    case SET_BROWSER_INCOMPATIBLE: {
      return {
        ...state,
        browserIncompatible: payload,
      };
    }
    default:
      return state;
  }
};

export default layoutReducer;
