import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import "react-toastify/dist/ReactToastify.css";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import "antd/dist/antd.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

ReactDOM.render(
  <HelmetProvider>
    <Helmet>
      <meta http-equiv="X-Frame-Options" content="DENY" />
    </Helmet>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
